import React from 'react';

const EllipsisOverflow: React.FunctionComponent = ({ children }) => {
  return (
    <div
      className="ellipsis-overflow"
      title={children ? children.toString() : ''}
    >
      {children}
      {/*language=CSS*/}
      <style jsx>{`
        .ellipsis-overflow {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      `}</style>
    </div>
  );
};

export default EllipsisOverflow;
