import pathToRegexp from 'path-to-regexp';
import { Paths } from '../urls/Paths';

export function stripHostIfMatches(url: string, host: string) {
  return String(url).replace(new RegExp('(http|https)*:?\\/\\/' + host), '');
}

export const pathRegexCacheMap: Record<string, RegExp> = {};

function matchStringToPath(maybePath: string, path: string) {
  // Generate RegExp lazily and cache them
  if (!pathRegexCacheMap[path]) {
    pathRegexCacheMap[path] = pathToRegexp(path);
  }
  return !!pathRegexCacheMap[path].exec(maybePath);
}

export function findPathForString(maybePath: string): string | undefined {
  return Object.keys(Paths).find((key) =>
    matchStringToPath(maybePath, Paths[key])
  );
}
