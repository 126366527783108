import React from 'react';
import { color, spacing } from '../../styles/theme';
import Icon, { IconName } from '../common/Icon';
import IconText from '../common/IconText';
import ButtonText from '../elements/ButtonText';
import Paragraph from '../elements/Paragraph';
import H from '../elements/H';
import Spacing from '../common/Spacing';

interface LiftBlockLinkBoxProps {
  topTitle: string;
  title: string;
  secondaryElement?: React.ReactNode;
}

export const LiftBlockLinkBoxIconWithLabel: React.FunctionComponent<{
  icon: IconName;
  iconLabel: string;
}> = ({ icon, iconLabel }) => {
  return (
    <div data-testid="link-box-icon">
      <IconText icon={icon} iconSize={2}>
        <Paragraph small>{iconLabel}</Paragraph>
      </IconText>
    </div>
  );
};

const LiftBlockLinkBox: React.FunctionComponent<LiftBlockLinkBoxProps> = ({
  topTitle,
  title,
  secondaryElement
}) => {
  return (
    <div className="lift-block-link-box">
      <div className="top-wrapper">
        <div className="top-left">
          <Spacing bottom={1}>
            <H styling="h5" color="gray" data-testid="link-box-title">
              {topTitle}
            </H>
          </Spacing>
          <ButtonText variant="primary">{title}</ButtonText>
        </div>
        <div className="top-right">
          <Icon name="nav-arrow-right" color="purple-light" size={4} />
        </div>
      </div>
      {secondaryElement && (
        <div className="bottom-wrapper">{secondaryElement}</div>
      )}
      {/*language=CSS*/}
      <style jsx>{`
        .lift-block-link-box {
          border-radius: ${spacing.one};
          background: ${color.white};
        }
        .top-wrapper {
          padding: ${spacing.one};
          display: flex;
        }
        .top-right {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: ${spacing.one};
        }
        .bottom-wrapper {
          padding: ${spacing.one};
          border-top: 1px dashed ${color.light};
        }
      `}</style>
    </div>
  );
};

export default LiftBlockLinkBox;
