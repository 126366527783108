import React, { ReactElement, ReactNode, useState } from 'react';
import chunk from 'lodash/chunk';
import SwipeableViews from 'react-swipeable-views';
import DivButton from '../a11y/DivButton';
import Row from '../grid/Row';
import Col from '../grid/Col';
import { baseXUnit, spacing } from '../../styles/theme';
import Spacing from './Spacing';
import FlexDiv from './FlexDiv';
import Icon from './Icon';

interface DesktopHorizontalListControlsProps {
  showControls: boolean;
  previousEnabled: boolean;
  nextEnabled: boolean;
  onClickPrevious: () => void;
  onClickNext: () => void;
  slideMargins?: number;
}

interface DesktopHorizontalListProps {
  elementsPerSlide: number;
  controls: React.FunctionComponent<DesktopHorizontalListControlsProps>;
  slideMargins?: number;
}

/**
 * Swipeable list which displays content using SwipeableViews. State and controls should be
 * placed into parent component.
 */
const SwipeableList: React.FunctionComponent<
  React.HTMLAttributes<HTMLElement> & {
    currentSlideIndex: number;
    setCurrentSlideIndex: (index: number) => void;
    slideGroups: ReactNode[][];
    colSize: number;
    slideMargins?: number;
  }
> = ({
  currentSlideIndex,
  setCurrentSlideIndex,
  slideGroups,
  colSize,
  slideMargins = 0
}) => {
  return (
    <SwipeableViews
      ignoreNativeScroll
      index={currentSlideIndex}
      onChangeIndex={(index) => setCurrentSlideIndex(index)}
      slideStyle={{
        overflow: 'hidden',
        paddingRight: spacing.fourth,
        paddingLeft: spacing.fourth
      }}
      data-testid="horizontal-card-list-desktop"
    >
      {slideGroups.map((group, index) => {
        return (
          <Spacing key={index} left={slideMargins} right={slideMargins}>
            <Row key={index}>
              {group.map((item, index) => {
                return (
                  <Col size={colSize} key={index}>
                    {item}
                  </Col>
                );
              })}
            </Row>
          </Spacing>
        );
      })}
    </SwipeableViews>
  );
};

/**
 * General purpose horizontal list built on top of React SwipeableViews.
 * Takes elements as children and requires controls parameter to be a React component which implements the
 * DesktopHorizontalListControlsProps interface.
 *
 * @param children          Elements to be shown in the list.
 * @param elementsPerSlide  Number of elements visible per slide. Should fulfill condition 24 mod elementsPerSlide === 0
 * @param controls          React component which renders controls to change the slides. Should implement specified interface.
 * @param [slideMargins]      Specifies left and right margins in the slide. Defaults to 0.
 * @constructor
 */
const DesktopHorizontalList: React.FunctionComponent<
  React.HTMLAttributes<HTMLElement> & DesktopHorizontalListProps
> = ({ children, elementsPerSlide, controls, slideMargins = 0 }) => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const slideGroups = chunk(React.Children.toArray(children), elementsPerSlide);
  const prevEnabled = currentSlideIndex > 0;
  const nextEnabled = currentSlideIndex < slideGroups.length - 1;

  if (24 % elementsPerSlide !== 0) {
    throw new Error(
      '24 % elementsPerSlide should produce 0 to render elements neatly to the grid with 24 columns!'
    );
  }

  const nextGroup = () => {
    if (nextEnabled) {
      setCurrentSlideIndex(currentSlideIndex + 1);
    }
  };
  const previousGroup = () => {
    if (prevEnabled) {
      setCurrentSlideIndex(currentSlideIndex - 1);
    }
  };

  const Controls = controls;
  return (
    <div className="desktop-horizontal-list">
      <Controls
        showControls={slideGroups.length > 1}
        previousEnabled={prevEnabled}
        nextEnabled={nextEnabled}
        onClickPrevious={previousGroup}
        onClickNext={nextGroup}
        slideMargins={slideMargins}
      />
      <SwipeableList
        currentSlideIndex={currentSlideIndex}
        setCurrentSlideIndex={setCurrentSlideIndex}
        slideGroups={slideGroups}
        slideMargins={slideMargins}
        colSize={24 / elementsPerSlide}
      />
      {/*language=CSS*/}
      <style jsx>{`
        .desktop-horizontal-list {
          position: relative;
        }
      `}</style>
    </div>
  );
};

/**
 * Returns a React component which can be used as controls for DesktopHorizontalList element.
 * @param [title]       Optional title element
 */
function getDesktopHorizontalListTopControls(
  title?: ReactElement
): React.FunctionComponent<DesktopHorizontalListControlsProps> {
  return ({
    showControls,
    previousEnabled,
    nextEnabled,
    onClickPrevious,
    onClickNext
  }) => {
    return (
      <Spacing bottom={4}>
        <div className="control-container">
          <FlexDiv justifyContent={title ? 'space-between' : 'flex-end'}>
            {title}
            {showControls && (
              <FlexDiv>
                <Spacing right={3}>
                  <DivButton
                    onClick={onClickPrevious}
                    disabled={!previousEnabled}
                    data-testid="prev-slide-button"
                  >
                    <Icon
                      name="nav-arrow-left"
                      size={4}
                      color={previousEnabled ? 'dark' : 'light-gray'}
                    />
                  </DivButton>
                </Spacing>
                <DivButton
                  onClick={onClickNext}
                  disabled={!nextEnabled}
                  data-testid="next-slide-button"
                >
                  <Icon
                    name="nav-arrow-right"
                    size={4}
                    color={nextEnabled ? 'dark' : 'light-gray'}
                  />
                </DivButton>
              </FlexDiv>
            )}
          </FlexDiv>
        </div>
        {/*language=CSS*/}
        <style jsx>{`
          .control-container {
            min-height: ${baseXUnit(4)};
          }
        `}</style>
      </Spacing>
    );
  };
}

/**
 * Render controls which will be overlaid on top of the list elements. Should be used as a parameter
 * for DesktopHorizontalList.
 */
const DesktopHorizontalListOverlayControls: React.FunctionComponent<DesktopHorizontalListControlsProps> =
  ({
    showControls,
    previousEnabled,
    nextEnabled,
    onClickPrevious,
    onClickNext,
    slideMargins = 0
  }) => {
    if (!showControls) {
      return null;
    }
    return (
      <div>
        <Spacing left={slideMargins}>
          {previousEnabled && (
            <DivButton
              className="desktop-horizontal-list-slide-button desktop-horizontal-list-previous-slide-button"
              onClick={onClickPrevious}
              disabled={!previousEnabled}
              data-testid="prev-slide-button"
            >
              <div className="icon-wrapper previous-slide-icon">
                <Icon
                  name="nav-arrow-left"
                  size={4}
                  color={previousEnabled ? 'white' : 'light-gray'}
                />
              </div>
            </DivButton>
          )}
        </Spacing>
        <Spacing right={slideMargins}>
          {nextEnabled && (
            <DivButton
              className="desktop-horizontal-list-slide-button desktop-horizontal-list-next-slide-button"
              onClick={onClickNext}
              disabled={!nextEnabled}
              data-testid="next-slide-button"
            >
              <div className="icon-wrapper next-slide-icon">
                <Icon
                  name="nav-arrow-right"
                  size={4}
                  color={nextEnabled ? 'white' : 'light-gray'}
                />
              </div>
            </DivButton>
          )}
        </Spacing>
        {/*language=CSS*/}
        <style jsx global>{`
          .desktop-horizontal-list-slide-button {
            z-index: 10;
            position: absolute;
            width: 40%;
            height: 100%;
            align-items: center;
            display: flex;
            opacity: 0.8;
          }
          .desktop-horizontal-list-next-slide-button {
            right: ${baseXUnit(slideMargins)};
          }
          .desktop-horizontal-list-previous-slide-button {
            left: ${baseXUnit(slideMargins)};
          }
          .desktop-horizontal-list-slide-button:hover {
            opacity: 1;
          }
        `}</style>
        {/*language=CSS*/}
        <style jsx>{`
          .icon-wrapper {
            border-radius: ${spacing.one};
            padding: ${spacing.one};
            background: rgba(0, 0, 0, 0.2);
          }
          .next-slide-icon {
            position: absolute;
            right: ${baseXUnit(2)};
          }
          .previous-slide-icon {
            position: absolute;
            left: ${baseXUnit(2)};
          }
        `}</style>
      </div>
    );
  };
export {
  getDesktopHorizontalListTopControls,
  DesktopHorizontalListOverlayControls,
  DesktopHorizontalList
};
