import React, { ReactElement } from 'react';
import { Parallax } from 'react-scroll-parallax/cjs';
import { baseXUnit } from '../../styles/theme';
import { ResponsiveProp } from '../../utils/layoutUtils';
import { CmsAsset } from '../../integrations/contentful/types';
import CmsMediaFixedHeight from '../cms/CmsMediaFixedHeight';

interface ImageWithLinkBoxProps {
  height: ResponsiveProp<number>;
  media: CmsAsset;
  liftBlockLinkBox: ReactElement;
  parallaxYOffset: [string, string];
  borderRadius?: ResponsiveProp<number>;
}

const ImageWithLinkBox: React.FunctionComponent<
  ImageWithLinkBoxProps & React.HTMLAttributes<HTMLElement>
> = ({
  height,
  media,
  parallaxYOffset,
  liftBlockLinkBox,
  borderRadius = 0,
  ...rest
}) => {
  return (
    <div
      className="image-with-link-box"
      {...rest}
      data-testid="image-with-link-box"
    >
      <div className="background-image">
        <CmsMediaFixedHeight
          media={media}
          fixedHeight={height}
          borderRadius={borderRadius}
        />
      </div>
      <div className="overlay">
        <Parallax y={parallaxYOffset}>{liftBlockLinkBox}</Parallax>
      </div>
      {/*language=CSS*/}
      <style jsx>{`
        .image-with-link-box {
          position: relative;
        }
        .overlay {
          position: absolute;
          top: ${baseXUnit(2)};
          left: ${baseXUnit(2)};
          max-width: 250px;
        }
      `}</style>
    </div>
  );
};

export default ImageWithLinkBox;
