import React, { useContext, useState } from 'react';
import { AnimationSegment } from 'lottie-web';
import { color } from '../../styles/theme';
import Paragraph from '../elements/Paragraph';
import Spacing from '../common/Spacing';
import { CmsCarouselSlide } from '../../integrations/contentful/types';
import MaybeRouteLink from '../navigation/MaybeRouteLink';
import Lottie from '../animations/Lottie';
import Button from '../elements/Button';
import ButtonText from '../elements/ButtonText';
import VideoBorderRadius from '../common/VideoBorderRadius';
import { WindowContext } from '../common/WindowContextProvider';

interface CarouselSlideProps {
  slide: CmsCarouselSlide;
}

function calculateLottieSegments(startFrame: number, endFrame: number) {
  const segments: AnimationSegment[] = [];
  if (startFrame > 0) {
    segments.push([0, startFrame - 1]);
  }
  // We want to loop only the frames between startFrame and endFrame,
  // so we add them as the last segment.
  segments.push([startFrame, endFrame]);
  return segments;
}

const LinkWrapper: React.FunctionComponent<{ url: string | undefined }> = ({
  children,
  url
}) =>
  url ? (
    <MaybeRouteLink
      noLinkStateStyles
      urlOrPath={url}
      data-cy="carousel-slide-link"
    >
      {children}
    </MaybeRouteLink>
  ) : (
    <>{children}</>
  );

const CarouselSlide: React.FunctionComponent<CarouselSlideProps> = ({
  slide
}) => {
  const [focused, setFocused] = useState<boolean>(false);
  const { breakpoint } = useContext(WindowContext);
  const shouldPlay = breakpoint === 'small' || focused;
  const activateFocus = () => setFocused(true);
  const removeFocus = () => setFocused(false);
  return (
    <div
      className="carousel-slide"
      data-cy="carousel-slide"
      onMouseEnter={activateFocus}
      onMouseLeave={removeFocus}
      onFocus={activateFocus}
      onBlur={removeFocus}
    >
      <LinkWrapper url={slide.fields.ctaUrl}>
        <div className="flex-wrapper">
          <div className="animation-wrapper">
            <VideoBorderRadius size={4} />
            {slide.fields.animation && (
              <Lottie
                animationPath={slide.fields.animation.fields.file.url}
                /* Animation should start when slide is visible to user in mobile. Unfortunately using react-intersection-observer
                caused bug here in mobile Safari. Animation was flickering when scrolling from one animation to another.
                That's why currently we just start animation immediately after page is loaded. */
                isPaused={!shouldPlay}
                loop
                segments={calculateLottieSegments(
                  slide.fields.loopStartFrame,
                  slide.fields.loopEndFrame
                )}
              />
            )}
          </div>
          <div className="slide-inner">
            <Spacing top={4} bottom={6} left={2} right={2}>
              <Paragraph>{slide.fields.content}</Paragraph>
              {slide.fields.ctaUrl && slide.fields.ctaText && (
                <div data-testid="cta-wrapper">
                  <Spacing top={4}>
                    <Button tag="div">
                      <ButtonText variant="primary" color="white">
                        {slide.fields.ctaText}
                      </ButtonText>
                    </Button>
                  </Spacing>
                </div>
              )}
            </Spacing>
          </div>
        </div>
      </LinkWrapper>
      {/*language=CSS*/}
      <style jsx>{`
        .animation-wrapper {
          position: relative;
        }
        .flex-wrapper {
          display: flex;
          flex-direction: column;
          background: ${color.white};
        }
        .slide-inner {
          flex-grow: 1;
          display: flex;
          height: 100%;
          flex-direction: column;
          justify-content: space-between;
        }
      `}</style>
    </div>
  );
};

export default CarouselSlide;
