import React from 'react';
import Paragraph from '../elements/Paragraph';
import Spacing from '../common/Spacing';

interface CmsTextareaContentProps {
  content: string;
}

const CmsTextareaContent: React.FunctionComponent<CmsTextareaContentProps> = ({
  content
}) => {
  return (
    <div>
      {content.split('\n\n').map((p, i) => (
        <Spacing key={i} bottom={2}>
          <Paragraph data-testid="paragraph">{p}</Paragraph>
        </Spacing>
      ))}
    </div>
  );
};

export default CmsTextareaContent;
