import React from 'react';
import { t } from '../../utils/i18n';
import { baseXUnit } from '../../styles/theme';
import { Asset } from '../../integrations/contentful/apiTypes';
import H from '../elements/H';
import Container from '../grid/Container';
import HorizontalCardList, { VisibleCards } from '../common/HorizontalCardList';
import Spacing from '../common/Spacing';
import IconText from '../common/IconText';
import CmsTextareaContent from '../cms/CmsTextareaContent';
import MemoryAuthorInfo from '../travellers/MemoryAuthorInfo';
import { CmsAsset } from '../../integrations/contentful/types';
import CallToActionBox from '../common/CallToActionBox';
import { Paths } from '../../urls/Paths';
import RouteLink from '../navigation/RouteLink';
import Paragraph from '../elements/Paragraph';
import DisplayOn from '../common/DisplayOn';
import {
  DesktopHorizontalList,
  DesktopHorizontalListOverlayControls
} from '../common/DesktopHorizontalList';
import CmsMediaFixedHeight from '../cms/CmsMediaFixedHeight';
import HyperLink from '../elements/HyperLink';

interface ExperiencePhaseModalContentProps {
  experiencePhase: {
    content: string;
    label: string;
    mainMedia: Asset;
    media?: readonly Asset[];
    title: string;
  };
  memory: {
    id: string;
    name: string;
    slug: string;
    startDate: string;
    endDate: string;
  };
  traveller: {
    id: string;
    name: string;
    picture: CmsAsset;
    slug: string;
  };
}

const imageHeightBaseMobile = 34;
const imageHeightBaseMediumToLarge = 45;

function renderMedia(media: CmsAsset) {
  return (
    <div data-testid="experience-phase-modal-image" key={media.sys.id}>
      <CmsMediaFixedHeight
        media={media}
        fixedHeight={[
          imageHeightBaseMobile,
          imageHeightBaseMediumToLarge,
          imageHeightBaseMediumToLarge
        ]}
        borderRadius={1}
      />
    </div>
  );
}

const ExperiencePhaseModalContent: React.FunctionComponent<ExperiencePhaseModalContentProps> =
  ({ experiencePhase, memory, traveller }) => {
    let mediaList = [experiencePhase.mainMedia];
    if (experiencePhase.media) {
      mediaList = mediaList.concat(experiencePhase.media);
    }

    return (
      <div data-cy="experience-phase-modal">
        <DisplayOn small medium>
          <HorizontalCardList visibleCards={VisibleCards.One}>
            {mediaList.map((media) => {
              return renderMedia(media);
            })}
          </HorizontalCardList>
        </DisplayOn>
        <DisplayOn large>
          <DesktopHorizontalList
            elementsPerSlide={1}
            slideMargins={7}
            controls={DesktopHorizontalListOverlayControls}
          >
            {mediaList.map((media) => {
              return renderMedia(media);
            })}
          </DesktopHorizontalList>
        </DisplayOn>
        <Spacing right={[0, 0, 2]} left={[0, 0, 2]}>
          <Container>
            <Spacing top={4} bottom={5}>
              <Spacing bottom={1}>
                <IconText icon="travel-activity">
                  <H styling="h4" tag="div" data>
                    {experiencePhase.label}
                  </H>
                </IconText>
              </Spacing>
              <H styling={'h2'}>
                <strong>{experiencePhase.title}</strong>
              </H>
            </Spacing>
            <Paragraph>
              <CmsTextareaContent content={experiencePhase.content} />
            </Paragraph>
            <Spacing top={4}>
              <MemoryAuthorInfo
                author={{
                  ...traveller
                }}
                memoryStartDate={memory.startDate}
                memoryEndDate={memory.endDate}
              />
            </Spacing>
            <Spacing top={8} bottom={8}>
              <div className="call-to-action-container">
                <RouteLink
                  path={Paths.Memory}
                  params={{
                    id: memory.id,
                    slug: memory.slug
                  }}
                >
                  <HyperLink noLinkStateStyles>
                    <CallToActionBox
                      actionName={t('Tutustu lomamuistoon')}
                      secondaryText={memory.name}
                    />
                  </HyperLink>
                </RouteLink>
              </div>
            </Spacing>
          </Container>
        </Spacing>
        {/*language=CSS*/}
        <style jsx>{`
          .call-to-action-container {
            margin-left: ${baseXUnit(7)};
            margin-right: ${baseXUnit(7)};
          }
        `}</style>
      </div>
    );
  };

export default ExperiencePhaseModalContent;
