import React, { useContext } from 'react';
import Link from 'next/link';
import { Paths, RoutePathsForPaths } from '../../urls/Paths';
import { findPathForString, stripHostIfMatches } from '../../utils/url';
import { SiteContext } from '../../pages/_app';
import HyperLink, { HyperLinkColor } from '../elements/HyperLink';

export interface MaybeRouteLinkProps {
  urlOrPath: string;
  noLinkStateStyles?: boolean;
  hyperLinkColor?: HyperLinkColor;
}

const MaybeRouteLink: React.FunctionComponent<
  MaybeRouteLinkProps & React.HTMLAttributes<HTMLElement>
> = ({ urlOrPath, children, hyperLinkColor, noLinkStateStyles, ...rest }) => {
  const { host } = useContext(SiteContext);
  const urlOrLocalPath = stripHostIfMatches(urlOrPath, host);
  const pathName = findPathForString(urlOrLocalPath);

  return pathName ? (
    <Link href={RoutePathsForPaths[Paths[pathName]]} as={urlOrLocalPath}>
      <HyperLink
        linkColor={hyperLinkColor}
        noLinkStateStyles={noLinkStateStyles}
        {...rest}
      >
        {children}
      </HyperLink>
    </Link>
  ) : (
    <HyperLink
      linkColor={hyperLinkColor}
      noLinkStateStyles
      href={urlOrPath}
      {...rest}
    >
      {children}
    </HyperLink>
  );
};

export default MaybeRouteLink;
