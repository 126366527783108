import React from 'react';
import HorizontalLine from '../common/HorizontalLine';
import {
  CmsBlock,
  instanceOfCmsCarouselBlock,
  instanceOfCmsLiftBlock,
  instanceOfCmsPopularMemoriesLiftBlock
} from '../../integrations/contentful/types';
import Spacing from '../common/Spacing';
import { t } from '../../utils/i18n';
import LiftBlock from './LiftBlock';
import CarouselBlock from './CarouselBlock';
import LiftedContentBlock from './LiftedContentBlock';

interface ContentBlockListProps {
  blocks: readonly CmsBlock[];
}

function renderBlockContent(block: CmsBlock) {
  if (instanceOfCmsLiftBlock(block)) {
    return <LiftBlock block={block} />;
  } else if (instanceOfCmsCarouselBlock(block)) {
    return <CarouselBlock block={block} />;
  } else if (instanceOfCmsPopularMemoriesLiftBlock(block)) {
    return (
      <LiftedContentBlock
        liftTitle={t('Suosittu lomamuisto')}
        contentDataElements={block.fields.popularMemories}
        height={[50, 80]}
        parallax={['120px', '240px']}
      />
    );
  }

  return null;
}

const ContentBlockList: React.FunctionComponent<ContentBlockListProps> = ({
  blocks
}) => {
  return (
    <div className="content-block-list">
      {blocks.map((block, index) => (
        <div
          className="block-wrapper"
          key={block.sys.id}
          data-testid="block-wrapper"
          data-cy="content-block-list-block"
        >
          {renderBlockContent(block)}
          {index !== blocks.length - 1 && (
            <Spacing bottom={14}>
              <HorizontalLine expand={false} />
            </Spacing>
          )}
        </div>
      ))}
    </div>
  );
};

export default ContentBlockList;
