import React, { ReactNode } from 'react';
import {
  CmsDestination,
  CmsMemory,
  instanceOfCmsDestination,
  instanceOfCmsMemory
} from '../../integrations/contentful/types';
import Spacing from '../common/Spacing';
import Row from '../grid/Row';
import Col from '../grid/Col';
import DisplayOn from '../common/DisplayOn';
import HorizontalList, { VisibleCards } from '../common/HorizontalCardList';
import RouteLink from '../navigation/RouteLink';
import { Paths } from '../../urls/Paths';
import ImageWithLinkBox from '../common/ImageWithLinkBox';
import {
  propToResponsivePropObject,
  ResponsiveProp
} from '../../utils/layoutUtils';
import MemoryAuthorInfo from '../travellers/MemoryAuthorInfo';
import HyperLink from '../elements/HyperLink';
import { imageHoverOpacity } from '../../styles/theme';
import LiftBlockLinkBox, {
  LiftBlockLinkBoxIconWithLabel
} from './LiftBlockLinkBox';

type ContentData = CmsDestination | CmsMemory;
interface LiftedContentProps {
  liftTitle: string;
  contentDataElements: readonly ContentData[];
  height: ResponsiveProp<number>;
  parallax: ResponsiveProp<string>;
}

const LiftedContentItem: React.FunctionComponent<{
  testId: string;
  contentData: ContentData;
  height: ResponsiveProp<number>;
  parallax: string;
  path: string;
  topTitle: string;
  secondaryElement?: ReactNode;
}> = ({
  testId,
  contentData,
  height,
  parallax,
  path,
  topTitle,
  secondaryElement
}) => {
  return (
    <div className="lifted-content-item">
      <RouteLink path={path} params={{ slug: contentData.fields.slug }}>
        <HyperLink data-testid={testId} noLinkStateStyles>
          {contentData.fields.mainImage && (
            <ImageWithLinkBox
              height={height}
              borderRadius={1}
              parallaxYOffset={['0px', parallax]}
              media={contentData.fields.mainImage}
              liftBlockLinkBox={
                <LiftBlockLinkBox
                  topTitle={topTitle}
                  title={contentData.fields.title}
                  secondaryElement={secondaryElement}
                />
              }
            />
          )}
        </HyperLink>
      </RouteLink>
      {/*language=CSS*/}
      <style jsx>{`
        /* We want opacity only to background image, not arrow icon or something else in parallax. */
        .lifted-content-item:hover :global(.background-image img) {
          opacity: ${imageHoverOpacity};
        }
      `}</style>
    </div>
  );
};

function renderLiftedContent(
  liftTitle: string,
  contentData: ContentData,
  height: ResponsiveProp<number>,
  parallax = '120px'
) {
  if (instanceOfCmsDestination(contentData)) {
    return (
      <LiftedContentItem
        testId="destination-lift"
        contentData={contentData}
        height={height}
        parallax={parallax}
        path={Paths.Destination}
        topTitle={liftTitle}
        secondaryElement={
          <LiftBlockLinkBoxIconWithLabel
            icon="travel-pin"
            iconLabel={contentData.fields.country}
          />
        }
      />
    );
  } else if (instanceOfCmsMemory(contentData)) {
    const memoryAuthor = contentData.fields.travellers[0];
    const authorElement = memoryAuthor.fields.profilePicture && (
      <Spacing top={0.5}>
        <MemoryAuthorInfo
          author={{
            name: memoryAuthor.fields.title,
            picture: memoryAuthor.fields.profilePicture,
            slug: memoryAuthor.fields.slug
          }}
          memoryStartDate={contentData.fields.startDate}
          memoryEndDate={contentData.fields.endDate}
          noLink
        />
      </Spacing>
    );

    return (
      <LiftedContentItem
        testId="memory-lift"
        contentData={contentData}
        height={height}
        parallax={parallax}
        path={Paths.Memory}
        topTitle={liftTitle}
        secondaryElement={authorElement}
      />
    );
  }
}

const LiftedContentBlock: React.FunctionComponent<LiftedContentProps> = ({
  contentDataElements,
  liftTitle,
  height,
  parallax
}) => {
  const resParallax = propToResponsivePropObject(parallax);
  return (
    <Spacing bottom={8}>
      <DisplayOn small>
        <HorizontalList expand visibleCards={VisibleCards.One}>
          {contentDataElements.map((contentData) => {
            return (
              <div key={contentData.sys.id}>
                {renderLiftedContent(
                  liftTitle,
                  contentData,
                  height,
                  resParallax.small
                )}
              </div>
            );
          })}
        </HorizontalList>
      </DisplayOn>
      <DisplayOn medium large>
        <Row>
          {contentDataElements.map((contentData, index) => {
            return (
              <Col size={12} key={index} data-testid="lifted-content">
                {renderLiftedContent(
                  liftTitle,
                  contentData,
                  height,
                  // TODO: add support for large specific parallax value. Now medium is used for both medium and large breakpoints
                  resParallax.medium
                )}
              </Col>
            );
          })}
        </Row>
      </DisplayOn>
    </Spacing>
  );
};

export default LiftedContentBlock;
