import React, { useState } from 'react';
import {
  CmsAsset,
  CmsDestination,
  CmsLiftBlock,
  CmsMemory,
  instanceOfCmsDestination,
  instanceOfCmsExperiencePhase,
  instanceOfCmsMemory,
  instanceOfCmsPhase
} from '../../integrations/contentful/types';
import { baseXUnit, imageHoverOpacity } from '../../styles/theme';
import { IconName } from '../common/Icon';
import HorizontalCardList, { VisibleCards } from '../common/HorizontalCardList';
import DestinationCard from '../destinations/DestinationCard';
import MemoryCard from '../memories/MemoryCard';
import {
  Destination,
  Entry,
  Memory
} from '../../integrations/contentful/apiTypes';
import Spacing from '../common/Spacing';
import RouteLink from '../navigation/RouteLink';
import { Paths } from '../../urls/Paths';
import BasicModal from '../common/BasicModal';
import { t } from '../../utils/i18n';
import ExperiencePhaseModalContent from '../destinations/ExperiencePhaseModalContent';
import DivButton from '../a11y/DivButton';
import Row from '../grid/Row';
import Col from '../grid/Col';
import DisplayOn from '../common/DisplayOn';
import H from '../elements/H';
import Container from '../grid/Container';
import { arrayHead } from '../../utils/array';
import ImageWithLinkBox from '../common/ImageWithLinkBox';
import HyperLink from '../elements/HyperLink';
import LiftBlockLinkBox, {
  LiftBlockLinkBoxIconWithLabel
} from './LiftBlockLinkBox';

interface LiftBlockProps {
  block: CmsLiftBlock;
}

const memoryIconName: IconName = 'travel-memory';
const destinationIconName: IconName = 'travel-pin';

function renderMainLift(
  block: CmsLiftBlock,
  displayExperiencePhase: boolean,
  setDisplayExperiencePhase: React.Dispatch<React.SetStateAction<boolean>>
) {
  const { mainLift } = block.fields;
  if (!mainLift) {
    return null;
  }

  const linkTarget = instanceOfCmsDestination(mainLift)
    ? mainLift
    : block.memory;
  const linkPath = instanceOfCmsDestination(mainLift)
    ? Paths.Destination
    : Paths.Memory;
  const { memory } = block;
  const traveller = memory ? arrayHead(memory.fields.travellers) : undefined;
  const isExperiencePhaseLift = instanceOfCmsExperiencePhase(mainLift);
  return (
    <div className="main-lift">
      {/*TODO: Add HotelPhase support once we have Hotel phase modals*/}
      {instanceOfCmsExperiencePhase(mainLift) &&
        memory &&
        traveller &&
        traveller.fields.profilePicture &&
        mainLift.fields.mainMedia && (
          <div>
            <DivButton
              onClick={() => {
                setDisplayExperiencePhase(true);
              }}
            >
              {renderMainLiftContent(block)}
            </DivButton>
            <BasicModal
              title={t('Lomakokemus')}
              showDialog={displayExperiencePhase}
              closeDialog={() => {
                setDisplayExperiencePhase(false);
              }}
            >
              <ExperiencePhaseModalContent
                experiencePhase={{
                  ...mainLift.fields,
                  mainMedia: mainLift.fields.mainMedia
                }}
                memory={{
                  id: memory.sys.id,
                  name: memory.fields.title,
                  slug: memory.fields.slug,
                  startDate: memory.fields.startDate,
                  endDate: memory.fields.endDate
                }}
                traveller={{
                  id: traveller.sys.id,
                  name: traveller.fields.title,
                  picture: traveller.fields.profilePicture,
                  slug: traveller.fields.slug
                }}
              />
            </BasicModal>
          </div>
        )}
      {!isExperiencePhaseLift && (
        <RouteLink
          path={linkPath}
          params={{ slug: linkTarget ? linkTarget.fields.slug : '' }}
        >
          <HyperLink>{renderMainLiftContent(block)}</HyperLink>
        </RouteLink>
      )}
      {/*language=CSS*/}
      <style jsx>{`
        /* We want opacity only to background image, not arrow icon or something else in parallax. */
        .main-lift:hover :global(.background-image img) {
          opacity: ${imageHoverOpacity};
        }
      `}</style>
    </div>
  );
}

function getLiftBlockSecondaryElement(block: CmsLiftBlock) {
  const { mainLift } = block.fields;
  if (instanceOfCmsPhase(mainLift) && block.memory) {
    return (
      <LiftBlockLinkBoxIconWithLabel
        icon={memoryIconName}
        iconLabel={block.memory.fields.title}
      />
    );
  }

  if (instanceOfCmsDestination(mainLift)) {
    return (
      <LiftBlockLinkBoxIconWithLabel
        icon={destinationIconName}
        iconLabel={mainLift.fields.country}
      />
    );
  }
}

function renderMainLiftContent(block: CmsLiftBlock) {
  const { mainLift, bottomLifts } = block.fields;
  if (!mainLift) {
    return null;
  }
  const media: CmsAsset =
    // @ts-ignore We know that either one exists.
    mainLift.fields.mainMedia || mainLift.fields.mainImage;
  const secondaryElement = getLiftBlockSecondaryElement(block);
  const renderDestinations = instanceOfCmsDestination(bottomLifts[0]);
  return (
    <ImageWithLinkBox
      data-cy="lift-block-main-lift"
      height={[70, renderDestinations ? 70 : 79]}
      media={media}
      parallaxYOffset={['0px', '220px']}
      borderRadius={[0, 1, 1]}
      liftBlockLinkBox={
        <LiftBlockLinkBox
          topTitle={block.fields.mainLiftTitle}
          title={mainLift.fields.title}
          secondaryElement={secondaryElement}
        />
      }
    />
  );
}

function renderDestinationCard(destination: CmsDestination) {
  if (!destination.fields.mainImage) {
    return null;
  }
  return (
    <DestinationCard
      key={destination.sys.id}
      country={destination.fields.country}
      mainImage={destination.fields.mainImage}
      name={destination.fields.title}
      slug={destination.fields.slug}
    />
  );
}

function renderMemoryCard(memory: CmsMemory) {
  const { mainImage, slug, startDate, endDate, title } = memory.fields;
  const traveller = arrayHead(memory.fields.travellers);
  if (!mainImage || !traveller || !traveller.fields.profilePicture) {
    return null;
  }
  return (
    <MemoryCard
      key={memory.sys.id}
      memory={{
        mainImage,
        slug,
        startDate,
        endDate,
        title
      }}
      traveller={{
        name: traveller.fields.title,
        profilePicture: traveller.fields.profilePicture,
        slug: traveller.fields.slug
      }}
    />
  );
}

function renderDesktopBottomLifts(
  bottomLifts: readonly Entry<Destination | Memory>[]
) {
  const renderDestinations = instanceOfCmsDestination(bottomLifts[0]);
  return (
    <div className="desktop-bottom-lifts">
      <Row>
        {renderDestinations
          ? bottomLifts
              .filter(instanceOfCmsDestination)
              .slice(0, 6)
              .map((destination) => (
                <Col size={[24, 8, 8]} key={destination.sys.id}>
                  <Spacing bottom={[0, 2, 6]}>
                    {renderDestinationCard(destination)}
                  </Spacing>
                </Col>
              ))
          : bottomLifts
              .filter(instanceOfCmsMemory)
              .slice(0, 6)
              .map((memory) => (
                <Col size={[24, 12, 12]} key={memory.sys.id}>
                  <Spacing bottom={[0, 2, 6]}>
                    {renderMemoryCard(memory)}
                  </Spacing>
                </Col>
              ))}
      </Row>
      {/*language=CSS*/}
      <style jsx>{`
        .desktop-bottom-lifts {
          margin-bottom: -${baseXUnit(6)};
        }
      `}</style>
    </div>
  );
}

function renderMobileBottomLifts(
  bottomLifts: readonly Entry<Destination | Memory>[]
) {
  const renderDestinations = instanceOfCmsDestination(bottomLifts[0]);
  const visibleCards = renderDestinations ? VisibleCards.Two : VisibleCards.One;
  return (
    <Spacing top={2}>
      <HorizontalCardList visibleCards={visibleCards}>
        {renderDestinations
          ? bottomLifts
              .filter(instanceOfCmsDestination)
              .map((destination) => renderDestinationCard(destination))
          : bottomLifts
              .filter(instanceOfCmsMemory)
              .map((memory) => renderMemoryCard(memory))}
      </HorizontalCardList>
    </Spacing>
  );
}

const LiftBlock: React.FunctionComponent<LiftBlockProps> = ({ block }) => {
  const { bottomLifts } = block.fields;
  const [displayExperiencePhase, setDisplayExperiencePhase] =
    useState<boolean>(false);
  return (
    <div className="lift-block" data-cy="lift-block">
      <Container noMobilePadding>
        <div className="title-wrapper">
          <Spacing bottom={[10, 5, 5]} left={[2, 0, 0]} right={[2, 0, 0]}>
            <H styling="h2">
              <strong>{block.fields.title}</strong>
            </H>
          </Spacing>
        </div>
        <Spacing bottom={12}>
          <Row>
            <Col size={[24, 24, 11]}>
              <Spacing bottom={[0, 2, 0]}>
                {renderMainLift(
                  block,
                  displayExperiencePhase,
                  setDisplayExperiencePhase
                )}
              </Spacing>
            </Col>
            <Col size={[0, 0, 1]} />
            <Col size={[24, 24, 12]}>
              <DisplayOn small>
                {renderMobileBottomLifts(bottomLifts)}
              </DisplayOn>
              <DisplayOn medium large>
                {renderDesktopBottomLifts(bottomLifts)}
              </DisplayOn>
            </Col>
          </Row>
        </Spacing>
      </Container>
      {/*language=CSS*/}
      <style jsx>{`
        .lift-block {
          overflow: hidden;
        }
        .title-wrapper {
          /* Prevent too long lines */
          max-width: 500px;
        }
      `}</style>
    </div>
  );
};

export default LiftBlock;
