import React from 'react';
import Modal, { ModalVariant } from '../common/Modal';
import ModalNavbar from './ModalNavbar';

export interface BasicModalProps {
  closeDialog: () => void;
  showDialog: boolean;
  title: string;
  variant?: ModalVariant;
}

const BasicModal: React.FunctionComponent<BasicModalProps> = ({
  children,
  closeDialog,
  showDialog,
  title,
  variant
}) => {
  return (
    <>
      <Modal
        className="basic-modal"
        data-testid="basic-modal"
        isOpen={showDialog}
        onDismiss={closeDialog}
        ariaLabel={title}
        variant={variant}
        animateIn
      >
        <ModalNavbar title={title} closeDialog={closeDialog} />
        {children}
      </Modal>
    </>
  );
};

export default BasicModal;
