import React from 'react';
import Icon from '../common/Icon';
import { baseXUnit, color } from '../../styles/theme';
import ButtonText from '../elements/ButtonText';
import H from '../elements/H';
import Button from '../elements/Button';
import EllipsisOverflow from './EllipsisOverflow';

interface CtaBoxProps {
  actionName: string;
  secondaryText?: string;
}
const CallToActionBox: React.FunctionComponent<CtaBoxProps> = ({
  actionName,
  secondaryText
}) => {
  return (
    <div className="call-to-action-box" data-cy="call-to-action-box">
      <div className="container">
        <Button spacing="small" className="call-to-action-button">
          <Icon name="nav-arrow-right" size={4} color="white" />
        </Button>
        <div className="text-container">
          <ButtonText variant="primary" lineHeight={2.5}>
            {actionName}
          </ButtonText>
          {secondaryText && (
            <div className="secondary-text">
              <H styling="h5" tag="span" color="gray">
                <EllipsisOverflow>{secondaryText}</EllipsisOverflow>
              </H>
            </div>
          )}
        </div>
      </div>
      {/*language=CSS*/}
      <style jsx>{`
        .container {
          display: flex;
          align-items: center;
          cursor: pointer;
        }
        .icon-container {
          height: ${baseXUnit(5)};
          border: 2px solid ${color.muted};
        }
        .text-container {
          height: ${baseXUnit(5)};
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          margin-left: ${baseXUnit(1)};
          overflow: hidden;
        }
        .secondary-text {
          line-height: ${baseXUnit(2.5)};
        }
        .call-to-action-box:hover :global(.call-to-action-button) {
          /* We want add hover styles to button also when mouse is on text. */
          background: ${color.purple};
          box-shadow: 0 ${baseXUnit(0.2)} ${baseXUnit(2)} 0
            rgba(124, 34, 195, 0.6);
        }
        .call-to-action-box:active :global(.call-to-action-button) {
          /* We want add hover active to button also when text is clicked. */
          background: ${color.pool};
          box-shadow: none;
        }
      `}</style>
    </div>
  );
};

export default CallToActionBox;
