import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import {
  BLOCKS,
  INLINES,
  Node,
  Document as RichTextDocument
} from '@contentful/rich-text-types';
import { RichText } from '../../integrations/contentful/apiTypes';
import Paragraph from '../elements/Paragraph';
import H from '../elements/H';
import MaybeRouteLink from '../navigation/MaybeRouteLink';
import Spacing from './Spacing';

interface ContentfulRichTextProps {
  richTextDocument: RichText;
}

export const hyperLinkRenderer = (node: Node, children: React.ReactNode) => {
  return (
    <span className="link">
      <MaybeRouteLink urlOrPath={node.data.uri} hyperLinkColor="purple-light">
        {children}
      </MaybeRouteLink>
    </span>
  );
};

const ContentfulRichText: React.FunctionComponent<ContentfulRichTextProps> = ({
  richTextDocument
}) => {
  const renderOptions = {
    renderNode: {
      [BLOCKS.HEADING_3]: (node: Node, children: React.ReactNode) => (
        <Spacing bottom={2}>
          <H styling="h3">
            <strong>{children}</strong>
          </H>
        </Spacing>
      ),
      [BLOCKS.HEADING_4]: (node: Node, children: React.ReactNode) => (
        <Spacing bottom={2}>
          <H styling="h4">
            <strong>{children}</strong>
          </H>
        </Spacing>
      ),
      [BLOCKS.PARAGRAPH]: (node: Node, children: React.ReactNode) => (
        <Spacing bottom={5}>
          <Paragraph>{children}</Paragraph>
        </Spacing>
      ),
      [INLINES.HYPERLINK]: hyperLinkRenderer
    }
  };

  return (
    <div className="rich-text" data-testid="rich-text">
      {documentToReactComponents(
        richTextDocument as RichTextDocument,
        renderOptions
      )}
    </div>
  );
};

export default ContentfulRichText;
