import React, { Fragment } from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import {
  BLOCKS,
  INLINES,
  Node,
  Document as RichTextDocument
} from '@contentful/rich-text-types';
import { CmsCarouselBlock } from '../../integrations/contentful/types';
import HorizontalCardList, { VisibleCards } from '../common/HorizontalCardList';
import DisplayOn from '../common/DisplayOn';
import Row from '../grid/Row';
import Col from '../grid/Col';
import Spacing from '../common/Spacing';
import H from '../elements/H';
import Container from '../grid/Container';
import { hyperLinkRenderer } from '../common/ContentfulRichText';
import { RichText } from '../../integrations/contentful/apiTypes';
import CarouselSlide from './CarouselSlide';

interface CarouselBlockProps {
  block: CmsCarouselBlock;
}

const CarouselBlockIngress: React.FunctionComponent<{
  richTextDocument: RichText;
}> = ({ richTextDocument }) => {
  const renderOptions = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node: Node, children: React.ReactNode) => (
        <H styling="h3">{children}</H>
      ),
      [INLINES.HYPERLINK]: hyperLinkRenderer
    }
  };

  return (
    <div className="rich-text" data-testid="rich-text">
      {documentToReactComponents(
        richTextDocument as RichTextDocument,
        renderOptions
      )}
    </div>
  );
};

const CarouselBlock: React.FunctionComponent<CarouselBlockProps> = ({
  block
}) => {
  const slides = block.fields.slides.map((slide) => (
    <CarouselSlide slide={slide} />
  ));
  return (
    <div
      className="carousel-block"
      data-cy="carousel-block"
      data-testid="carousel-block"
    >
      <Container noMobilePadding>
        <Row>
          <Col size={[0, 0, 3]} />
          <Col size={[24, 24, 21]}>
            <div className="content-wrapper">
              <Spacing bottom={[5, 8, 8]} left={[2, 0, 0]}>
                <H styling="h2">
                  <strong>{block.fields.title}</strong>
                </H>
                <Spacing top={[4, 5, 5]}>
                  <CarouselBlockIngress
                    richTextDocument={block.fields.ingress}
                  />
                </Spacing>
              </Spacing>
            </div>
          </Col>
        </Row>
        <Spacing bottom={8}>
          <DisplayOn small>
            <HorizontalCardList visibleCards={VisibleCards.One}>
              {slides.map((slide, index) => (
                <div
                  className="slide-wrapper"
                  data-testid="carousel-block-slide"
                  key={index}
                >
                  {slide}
                </div>
              ))}
            </HorizontalCardList>
          </DisplayOn>
          <DisplayOn medium large>
            <Row>
              <Col size={[0, 0, 3]} />
              {slides.map((slide, index) => (
                <Fragment key={index}>
                  <Col size={[0, 8, 5]}>{slide}</Col>
                  <Col size={[0, 0, 1]} />
                </Fragment>
              ))}
            </Row>
          </DisplayOn>
        </Spacing>
      </Container>
      {/*language=CSS*/}
      <style jsx>{`
        .content-wrapper {
          /* Prevent too long lines */
          max-width: 625px;
        }
        .slide-wrapper {
          /* All slides should be equal height*/
          display: flex;
          height: 100%;
        }
      `}</style>
    </div>
  );
};

export default CarouselBlock;
